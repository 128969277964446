@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');;
@font-face {
  font-family: 'Liberty';
  src: url(../fonts/liberty.otf);
}

*{box-sizing: border-box;}
body{ margin: 0;}
.sf-main-loader { position: absolute; left: 0; right: 0; margin: auto; width: 100%; height: 100%; background: #333333; top: 0; z-index: 111; display: flex; align-items: center; justify-content: center; }
.sf-main-loader img { max-width: 100px; }
.scoreboard-frame{font-family: "Manrope", sans-serif; font-optical-sizing: auto; background: #333; border-radius: 10px; min-height: 150px; padding:6px 10px; line-height: 1; color: #fff; max-width: 100%; position: relative; overflow: hidden;}
.scoreboard-frame img{max-width: 100%; vertical-align: middle;}
.scoreboard-frame .sf-wraper { max-width: 1300px; margin: auto; }
.sf-match-toss-result { display: flex; justify-content: center; font-weight: 400; text-align: center; gap: 8px; margin: 0 auto 4px; font-size: 13px; border-bottom: 1px solid #ffffff14; padding: 0 0 5px; }
.sf-match-toss-result .match-format { background: #009688; padding: 2px 7px; font-size: 10px; font-weight: 500; border-radius: 6px; animation: blinker 1s linear; vertical-align: top; }
.sf-match-result { display: flex; justify-content: space-between;}
.sf-match-result .sf-mr-box { width: 50%;}
.sf-match-result .sf-mr-box:nth-child(2) { width: 100%; max-width: 420px; display: flex; align-items: center; justify-content: center; padding: 5px 15px; position: relative;}
.sf-team-name { font-weight: 700; font-size: 18px; display: flex; align-items: center; gap: 5px; white-space: nowrap;}
.sf-run-detail { display: flex; margin: 0 0; flex-direction: column; text-align: right;}
.sf-run-detail .sf-team-run { font-size: 18px; white-space: nowrap; font-weight: 700;}
.sf-run-detail .sf-crr { font-size: 10px; margin: 1px 0 0; text-align: right; color: darksalmon; font-weight: 600; white-space: nowrap;}
.sf-run-detail .sf-crr span.sf-rrr { margin-left: 3px; padding-left: 8px; position: relative; }
.sf-run-detail .sf-crr span.sf-rrr:after { content: "|"; position: absolute; left: 0; top: 2px; font-size: 120%; }
.sf-ball-result-effect{position: relative; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;}
.ball-result{ padding: 6px 25px; border-radius: 50px; font-size: 24px; text-transform: uppercase; text-align: center; background: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 20%, rgba(255,255,255, 0.3) 30%, rgba(255,255,255, 0.3) 30%,rgba(0, 0, 0, 0.1) 60%,rgba(0, 0, 0, 0.1) 100%); background-repeat: no-repeat; background-position: 0%; background-size: 300%; font-weight: 700; position: relative; z-index: 1; overflow: hidden; display: inline-flex; align-items: center; justify-content: center; min-width: 180px; min-height: 45px; animation: light 1s infinite, pulse 2s 9s infinite, flash 2s 500s infinite; background-color: #02a9a9; }
.ball-result.xx-lg-fn{font-size: 24px;}
.ball-result.x-lg-fn{font-size: 20px;}
.ball-result.lg-fn{font-size: 18px;}
.ball-result.md-fn{font-size: 16px;}
.ball-result.sm-fn{font-size: 14px;}
.ball-result.updated{animation: light 1s infinite; }
.ball-result.updated span{animation: BallUpdatedanim 1s;}
.sf-team-details { max-width: 350px; }
.sf-team-details.right {margin-left: auto;}
.sf-team-details .sf-score-details { display: flex; justify-content: space-between; align-items: flex-start; gap: 10px; padding: 0 0 3px; min-height: 33px;}
.sf-team-name span.flag { display: inline-block; vertical-align: middle;}
.sf-team-name span.flag img{max-height: 18px; max-width: 22px;}
.sf-team-name span.current_play_status { width: 20px; }
.sf-over-details { display: flex; align-items: center; justify-content: center; gap: 10px; }
.sf-footer { padding: 5px 0 0; border-top: 1px solid #ffffff14; margin:3px 0 0; display: flex; justify-content: center; align-items: center; gap: 10px; position: relative;}
.trial-lead { font-size: 12px; color: darkturquoise; animation: flash 2s infinite; margin-top: 5px; text-align: center;}
.sf-overs { display: flex; align-items: center; justify-content: center; gap: 5px; font-size: 14px; white-space: nowrap; overflow: auto;}
.sf-overs span.ball { display: flex; min-width: 23px; min-height: 23px; border-radius: 16px; border: 1px solid #fff; font-size: 11px; line-height: 10px; align-items: center; justify-content: center; text-transform: uppercase;}
.sf-overs span.seprator { min-width: 1px; background: #fff; min-height: 18px; display: inline-block; margin: 0px 4px; vertical-align: middle; }
.sf-overs span.ball.four { background: cornflowerblue; border-color: cornflowerblue; }
.sf-overs span.ball.six{background: sandybrown; border-color: sandybrown;}
.sf-overs span.ball.loss-ball{background: red; border-color: red;}
.sf-overs span.ball.nxt-ball{animation: pulse 1s infinite;}
.sf-overs span.ball.prv-ovr{opacity: 0.5;}
.sf-player-current-status table{border-collapse: collapse; width: 100%;}
.sf-player-current-status table th { background: #ffffff2b; }
.sf-player-current-status table th, .sf-player-current-status table td{padding:2px 5px; font-size: 12px; font-weight: 400; text-align: left; white-space: nowrap;}

.sf-error-msg { position: absolute; left: 0; right: 0; top: 0; height: 100%; background: #333; z-index: 11; display: flex; align-items: center; justify-content: center; }
.sf-error-msg h1 { margin: 0; padding: 0 10px; color: red; background: linear-gradient(135deg, red 20%, #fff 30%, #fff 30%, red 60%, red 100%); background-repeat: no-repeat; background-position: 0%; background-size: 300%; animation: light 2s infinite, pulse 1s; -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-family: 'Liberty'; display: flex; width: 100%; text-align: center; align-items: center; justify-content: center; font-size: 40px; text-shadow: 0 1px 20px red}
.sf-error-msg h1 img { max-width: 100px; filter: invert(1) drop-shadow(0px 0px 5px red); margin-right: 30px;}
.live-fulldata{position: absolute; left: 0; top: 0; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center; z-index: 1111111; background: radial-gradient(circle, #555555de 40%, transparent 100%); opacity:0; pointer-events: none;}
.live-fulldata img{transform: scale(0); transition: 0.5s; font-size: 45px; font-weight: 700; text-transform: uppercase; position: absolute; left: 0; right: 0; margin: auto;}
.live-fulldata.highlight-ball{opacity:1; pointer-events: auto;}
.live-fulldata.highlight-ball img.show{transform: scale(1); transition: 0.5s;}
.background-video{position: absolute; top: 50%; left: 50%; width: auto; height: auto; filter: grayscale(1); opacity: 0.1; transform: translate(-50%, -50%); min-width: 100%; pointer-events: none;}
.scoreboard-frame span.team-l-name { display: none; }
.sf-inning-status{ position: absolute; top: -5px; background: chocolate; font-size: 11px; padding: 1px 20px 4px; line-height: 11px; clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%); }
.sf-team-name span.current_play_status .ball-icon { padding: 2px; }

/* Animation Keyframe */
@keyframes light {
0% {
background-position:0%;
}
100% {
background-position: 100%;
}
}

@keyframes flash {
0% {
opacity: 1;
}
50% {
opacity: 0;
}
100% {
opacity: 1;
}
}
@keyframes pulse {
0% {
transform: scale(1);
}
30% {
transform: scale(1.05);
}
}
@keyframes BallUpdatedanim {
0% {
transform: scale(1);
}
50% {
transform: scale(0);
}
100% {
transform: scale(1);

}
}

/* Match Upcoming Css */
.scoreboard-frame.upcoming .ball-result { font-size: 16px; width: 100%; max-width: 230px;}
.scoreboard-frame.upcoming .sf-player-current-status { display: none;}
.scoreboard-frame.upcoming .sf-team-details .sf-score-details { min-height: 92px; align-items: center; justify-content: center; padding-bottom: 0;}
.scoreboard-frame.upcoming .sf-team-details.right .sf-score-details .sf-team-name { flex-direction: row-reverse; }
.scoreboard-frame.upcoming .sf-run-detail{display: none;}
.scoreboard-frame.upcoming span.team-s-name { display: none; }
.scoreboard-frame.upcoming span.team-l-name { display: block; }

@media (max-width:999px) {
  .sf-team-details{max-width: 100%;}
  .ball-result{min-width: 160px; min-height: 38px;}
  .sf-match-result .sf-mr-box:nth-child(2){padding: 5px 12px;}
  .sf-run-detail .sf-team-run, .sf-team-name{font-size: 16px;}
  .sf-error-msg h1{font-size: 36px;}
  .ball-result.xx-lg-fn{font-size: 18px;}
  .ball-result.x-lg-fn{font-size: 16px;}
  .ball-result.lg-fn{font-size: 12px;}
  .ball-result.md-fn{font-size: 11px;}
  .ball-result.sm-fn{font-size: 10px;}
}
@media (max-width:767px) {
  .sf-player-current-status{display: none;}
  .sf-team-details .sf-score-details{flex-direction: column;}
  .sf-match-result .sf-mr-box{width: 100%; min-height: 77px; max-width: 140px;}
  .sf-match-result .sf-mr-box:nth-child(2) { padding: 0 6px; border-left: 1px solid #ffffff14; border-right: 1px solid #ffffff14; width: 100%; max-width: 100%;}
  .sf-run-detail .sf-team-run, .sf-team-name{font-size: 14px;}
  .sf-match-toss-result{font-size: 12px; min-height: 28px; align-items: center;}
  .ball-result { min-width: 150px; min-height: 34px; padding: 6px 15px; }
  .sf-run-detail .sf-team-run span { display: block; text-align: left; }
  .sf-team-details.right .sf-score-details { align-items: flex-end; }
  .trial-lead{font-size: 11px;}
  .sf-team-details.right .sf-score-details .sf-team-name { flex-direction: row-reverse; }
  .sf-error-msg h1{font-size: 30px;}
  .sf-error-msg h1 img{margin-right: 15px;}

  /* Match Upcoming Css */
  .scoreboard-frame.upcoming .ball-result{ font-size: 14px; white-space: nowrap;}
  .scoreboard-frame.upcoming .sf-match-result .sf-mr-box{max-width: 100%;}
  .scoreboard-frame.upcoming .sf-match-result .sf-mr-box:nth-child(2){border: 0;}
  .scoreboard-frame.upcoming .sf-team-details .sf-score-details{min-height: 75px;}
}
@media (max-width:480px) {
  .ball-result{min-width: 120px;}
  .trial-lead{font-size: 9px;}
  .sf-match-toss-result{font-size: 11px;}
  .sf-run-detail .sf-team-run, .sf-team-name{font-size: 13px;}
  .ball-result.xx-lg-fn{font-size: 16px;}
  .ball-result.x-lg-fn{font-size: 14px;}
  .ball-result.lg-fn{font-size: 11px;}
  .ball-result.md-fn{font-size: 10px;}
  .ball-result.sm-fn{font-size: 8px;}
  .sf-match-result .sf-mr-box{max-width: 120px;}
  .sf-error-msg h1{font-size: 26px; }
  .sf-error-msg h1 img{margin-right: 0;}
  .sf-run-detail .sf-crr{flex-direction: column; display: flex;}
  .sf-run-detail .sf-crr span.sf-rrr { padding-left: 0; margin: 0; }
  .sf-run-detail .sf-crr span.sf-rrr:after{display: none;}
  .sf-inning-status { font-size: 9px; padding: 1px 15px 2px; line-height: 10px; }
  .sf-team-name span.flag img { max-height: 15px; max-width: 18px; }
  .sf-team-name span.current_play_status { width: 11px; }
  .trial-lead{margin-top: 3px;}
  .sf-team-name span.current_play_status .ball-icon{padding: 1px;}

  /* Match Upcoming Css */
  .scoreboard-frame.upcoming .ball-result{ font-size: 12px; }
  .scoreboard-frame.upcoming .sf-match-result .sf-mr-box:nth-child(2){min-width: 160px;}
  .scoreboard-frame.upcoming span.team-l-name { display: none; }
  .scoreboard-frame.upcoming span.team-s-name { display: block; }
}
@media (max-width:350px) {
  .ball-result { min-width: 110px; min-height: 30px; padding: 3px 10px; }
  .sf-run-detail .sf-team-run, .sf-team-name{font-size: 12px;}
}